const cdnBrandList = [
  {
    src: 'https://static.mlytics.com/images/website/logo_cdn_brand_cloudfront.png',
    alt: 'CloudFront'
  },
  {
    src: 'https://static.mlytics.com/images/website/logo_cdn_brand_GlobalAccelerator.png',
    alt: 'Global Accelerator'
  },
  {
    src: 'https://static.mlytics.com/images/website/logo_cdn_brand_Akamai.png',
    alt: 'Akamai'
  },
  {
    src: 'https://static.mlytics.com/images/website/logo_cdn_brand_VNCDN.png',
    alt: 'VNCDN'
  },
  {
    src: 'https://static.mlytics.com/images/website/logo_cdn_brand_CDNetworks.png',
    alt: 'CDNetworks'
  },
  {
    src: 'https://static.mlytics.com/images/website/logo_cdn_brand_ChunghwaTelecom.png',
    alt: 'Chunghwa Telecom'
  },
  {
    src: 'https://static.mlytics.com/images/website/logo_cdn_brand_BaishanCloud.png',
    alt: 'Baishan Cloud'
  },
  {
    src: 'https://static.mlytics.com/images/website/logo_cdn_brand_WangSu.png',
    alt: 'Wangsu'
  },
  {
    src: 'https://static.mlytics.com/images/website/logo_cdn_brand_ChinaCache.png',
    alt: 'ChinaCache'
  },
  {
    src: 'https://static.mlytics.com/images/website/logo_cdn_brand_TencentCloud.png',
    alt: 'Tencent Cloud'
  },
  {
    src: 'https://static.mlytics.com/images/website/logo_cdn_brand_AlibabaCloud.png',
    alt: 'Alibaba Cloud'
  }
]
const chinaCDNList = [
  {
    src: 'https://static.mlytics.com/images/website/logo_cdn_brand_GlobalAccelerator.png',
    alt: 'Global Accelerator'
  },
  {
    src: 'https://static.mlytics.com/images/website/logo_cdn_brand_TencentCloud.png',
    alt: 'Tencent Cloud'
  },
  {
    src: 'https://static.mlytics.com/images/website/logo_cdn_brand_AlibabaCloud.png',
    alt: 'Alibaba Cloud'
  },
  {
    src: 'https://static.mlytics.com/images/website/logo_cdn_brand_BaishanCloud.png',
    alt: 'Baishan Cloud'
  },
  {
    src: 'https://static.mlytics.com/images/website/logo_cdn_brand_ChinaCache.png',
    alt: 'ChinaCache'
  },
  {
    src: 'https://static.mlytics.com/images/website/logo_cdn_brand_WangSu.png',
    alt: 'Wangsu'
  },
  {
    src: 'https://static.mlytics.com/images/website/logo_cdn_brand_CDNetworks.png',
    alt: 'CDNetworks'
  }
]

export { cdnBrandList, chinaCDNList }
