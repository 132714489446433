import React, { useEffect } from 'react'

import './chinaAccessGuideForm.css'

const chinaAccessGuideForm = () => {
  useEffect(() => {
    const script = document.createElement('script')
    const createHsForm = () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          target: '#hsGetChinaAccessGuideForm',
          region: 'na1',
          portalId: '4284310',
          formId: 'b98b7cf0-0e7e-4d52-9396-c67e3876fd54'
        })
      }
    }

    script.src = '//js.hsforms.net/forms/embed/v2.js'
    document.body.appendChild(script)
    script.addEventListener('load', createHsForm)
  }, [])

  return <div id="hsGetChinaAccessGuideForm" style={{ minHeight: 600 }} />
}

export default chinaAccessGuideForm
